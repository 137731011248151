#article {

  #icon {
    border: solid 6px _palette(projectBorder);
    border-radius: 30%;
    width: 200px;
    height: 200px;
    margin-top: 0;
  }

  #largeImage {
    width: 90%;
    height: auto;
  }

  #medImage {
    width: 60%;
    height: auto;

    @include respond-to('large') {
      width: 80%;
    }
  }

  #smallImage {
    width: 40%;
    height: auto;

    @include respond-to('large') {
      width: 70%;
    }
  }

  #aboutImage {
    width: 50%;
    height: auto;
    border-radius: 5%;
    border: solid 2px _palette(aboutBorder);

    @include respond-to('large') {
      width: 70%;
    }
  }

  img {
    margin: 2em;
    margin-left: auto;
    margin-right: auto;
    display: block;
    float: none;
    width: auto;
    height: auto;
    border-radius: 0;
    padding: 0;
  }

  video {
    margin: 2em;
    margin-left: auto;
    margin-right: auto;
    display: block;
    float: none;
    width: auto;
    height: auto;
    border-radius: 0;
    padding: 0;
  }

  h2 {
    text-align: center;
  }

  ul {
    list-style: disc;
    padding-left: 5em;
    padding-right: 5em;

    @include respond-to('large') {
      padding-left: 4em;
      padding-right: 4em;
    }

    @include respond-to('medium') {
      padding-left: 1.5em;
      padding-right: 1.5em;
    }

    li {
      display: inline-block;
      display: list-item;
    }
  }

  h3 {
    text-align: left;
    padding-left: 3em;
    @include respond-to('large') {
      padding-left: 2em;
    }

    @include respond-to('medium') {
      padding-left: 0.3em;
    }
  }

  h4 {
    text-align: center;
  }

  p {
    padding-left: 5em;
    padding-right: 5em;

    @include respond-to('large') {
      padding-left: 3em;
      padding-right: 3em;
    }

    @include respond-to('medium') {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }

  #side {
    display: inline-block;
  }

  #sideBySide {
    text-align: center;
  }

  code {
    display: inline-block;
    background-color: _palette(bg-alt);

    @include respond-to('medium') {
      font-size: _size(med-code);
    }
  }

  pre {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  span {
    display: flex;
    justify-content: center;
  }

  iframe {
    max-width: 100vw;
    max-height: 56.25vw;
  }

  .back {
    border: none;
    font-size: 3em;
    float: none;
    display:inline;
  }
}
