body {
  color: _palette(fg);
  font-family: _font(family);
  font-size: 12pt;
  font-weight: _font(weight);
  line-height: 1.75;
}

a {
	border-bottom: dotted 1px _palette(border-alt);
	color: inherit;
	text-decoration: none;
  transition: color 0.2s ease, border-bottom-color 0.2s ease;

	&:hover {
		border-bottom-color: transparent;
		color: _palette(accent) !important;

		&:before {
			color: _palette(accent) !important;
		}
	}
}

h1 {
	color: _palette(fg-bold);
	font-family: _font(family-heading);
	font-weight: _font(weight-heading-bold);
	letter-spacing: _font(kerning-heading);
	line-height: 1.65;
	margin: 0 0 (_size(element-margin) * 0.5) 0;
	text-transform: uppercase;
  font-size: 10em;

	a {
		color: inherit;
		border-bottom: 0;
	}
}

h2 {
  font-family: _font(family-heading);
	font-weight: _font(weight-heading-bold);
  font-size: 2em;

  @include respond-to('medium') {
    text-align: center;
  }

  margin: 0;
}

ul {
		list-style: none;

		li {
      display: inline;
		}

    a {
      border: none;
    }

}

.content {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5em;
  margin-bottom: 5em;
  padding: 1em;
  border-radius: 15px 50px 30px;
  border: solid 1px _palette(border);
  background-color: _palette(bg);
  font-family: _font(family-heading);
  overflow: auto;

  @include respond-to('large') {
      width: 80%;
  }
}
