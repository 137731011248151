@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "Cannot find `#{$breakpoint}`. "
        + "Available breakpoints: #{map-keys($breakpoints)}.";
  }
}
