p {
  margin-right: 2em;
  @include respond-to('large') {
    margin-right: 1em;
    margin-left: 1em;
  }
}

img {
  float: left;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin-right: 2em;

  @include respond-to('medium') {
    float: none;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 1em;
  }
}
