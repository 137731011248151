.media {

  font-size: 2.5em;
  margin: 0;

  @include respond-to('large') {
    font-size: 2em;
  }

  @include respond-to('medium') {
    font-size: 1.8em;
  }

  li {
    padding-right: 0.5em;
  }
}
