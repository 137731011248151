body {
  padding-top: 3.5em;
  background-color: _palette(bg-alt);
}

#header {
  display: flex;
  background-color: _palette(bg);
  border-bottom: solid 1px _palette(border);
  height: 3.5em;
  left: 0;
  line-height: 3.5em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;

  a {
    color: inherit;
    text-decoration: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
    li {
      display: inline-block;
      padding-left: 0;
    }
  }

  .index-link {
    flex: 1;
    z-index: 3;
    margin-right: auto;
    margin-left: 1em;
    a {
      border-bottom: 0;
      font-family: _font(family-heading);
      font-size: 0.8em;
      font-weight: _font(weight-heading-bold);
      letter-spacing: _font(kerning-heading);
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  .space {
    flex: 1;
    margin-left: auto;
    margin-right: 1em;
  }

  .links {
    flex: 1;
    display: flex;
    justify-content: center;
    height: inherit;
    line-height: inherit;
    flex-grow: 3;
    overflow: hidden;

    ul {
      li {
        border-left: solid 1px _palette(border);
        line-height: 1;
        margin-left: 1em;
        padding-left: 1em;

        &:first-child {
          border-left: 0;
          margin-left: 0;
          padding-left: 0;
        }

        a {
          border-bottom: 0;
          font-family: _font(family-heading);
          font-size: 0.8em;
          font-weight: _font(weight-heading);
          letter-spacing: _font(kerning-heading);
          text-transform: uppercase;
          color: _palette(fg-light);
        }
      }
    }

    @include respond-to(large) {
      display: none;
    }
  }

  .bm-item {
    display: inline-block;

    /* Our sidebar item styling */
    text-decoration: none;
    margin-bottom: 10px;
    color: #ff0000;
    transition: color 0.2s;
  }

  /* Change color on hover */
  .bm-item:hover {
    color: black;
  }

  /* The rest copied directly from react-burger-menu docs */

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: _size(element-margin);
    height: _size(element-margin);
    right: 1em;
    top: 1em;

    display: none;

    @include respond-to('large') {
      display: initial;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: _palette(black);
  }

  /* General sidebar styles */
  .bm-menu {
    background: _palette(fg-transparent);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    border-left: solid 1px _palette(border);
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .bm-item-list {
    overflow: auto;
    outline: none;
  }

  .index-li {
    float: left;
    display: block;
    width: 100%;
    line-height: 2;

    a {
      border: 0;
      width: 100%;
      font-family: _font(family-heading);
      font-size: 0.8em;
      font-weight: _font(weight-heading);
      letter-spacing: _font(kerning-heading);
      text-transform: uppercase;
      color: _palette(fg);
    }
  }

  .hamburger-ul  {
    outline: none;
  }

}
